import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';
import Img from 'common/components/Img';
import IconSuccess from 'assets/images/success.png';

import './style.scss';

const Index = (props) => {
    const { isModalVisible = false, setIsModalVisible = () => { } } = props;
    const [numberTime, setNumber] = useState(0)
    const timer = useRef(null)

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const clearTime = () => {
        setIsModalVisible(false)
        clearInterval(timer.current)
    }
    const timerFun = () => {
        let max = 3;
        setNumber(max)
        timer.current = setInterval(() => {
            if (max < 1) {
                clearTime()
            }
            setNumber(max)
            max--;
        }, 1000)
    }
    useEffect(() => {
        if (isModalVisible) {
            timerFun()
        }
        if (!isModalVisible) {
            clearTime()
        }
        // eslint-disable-next-line
    }, [isModalVisible])
    return <>
        <Modal
            zIndex={1400}
            title={<Img className="icon-form" src={IconSuccess} />}
            wrapClassName="success-modal"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            forceRender={true}
            width={500}>
            <div className="content">
                <div className='show-text'>
                    申请已提交，请关注来电及邮件，<br />
                我们会在两个工作日内给您反馈。
                </div>
                <p className='tip'>{numberTime}秒后自动关闭弹窗</p>
            </div>
        </Modal>
    </>
}
export default Index;