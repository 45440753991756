import React from 'react';
import ReactDOM from 'react-dom';
import config from 'config/index';
import App from './App';
import './index.scss'
import reportWebVitals from './reportWebVitals';

var sUserAgent = navigator.userAgent;
if (sUserAgent.indexOf('Android') > -1 ||
    sUserAgent.indexOf('iPhone') > -1 ||
    sUserAgent.indexOf('iPad') > -1 ||
    sUserAgent.indexOf('iPod') > -1 ||
    sUserAgent.indexOf('Symbian') > -1) {
    window.location.href = config.PHONE_URL;
}

ReactDOM.hydrate(<App /> , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();