import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import LogoImg from 'assets/images/icon_logo.png';
import FormModal from 'common/components/FormModal';
import './style.scss';

const Index = (props) => {
    const { history = {}, location = {} } = props;
    const [activePath, setActivePath] = useState('')

    const list = [{
        name: '首页',
        path: '/'
    }, {
        name: '产品',
        path: '/product',
        children: [{
            path: '/product/ai',
            name: '智能AI面试',
        }, {
            path: '/product/trm',
            name: '智能TRM',
        }, {
            path: '/product/ssc',
            name: '智能SSC',
        }, {
            path: '/product/develop',
            name: '智能发展追踪',
        }, {
            path: '/product/practise',
            name: '智能陪练',
        }]
    }, {
        name: '关于我们',
        path: '/about'
    }]

    useEffect(() => {
        setActivePath(location.pathname);
    }, [location])

    const goPage = (pageData) => {
        history.push(pageData.path)
    }

    const isActive = (key, pageData = {}) => {
        const { path = '', children = null } = pageData
        if (key === path) {
            return true
        }

        let status = false;
        if (children) {
            let activeData = children.find(item => {
                return item.path === key
            })
            status = !!activeData;
        }
        return status
    }
    return <section className={'header'}>
        <img className={'logo'} src={LogoImg} alt="HRTPS" onClick={() => history.push('/')} />
        <ul className={'menu'}>
            {list.map((item) => {
                if (item.children) {
                    return <li key={item.path}
                        className={classnames('sub-menu-item', {
                            ['menu-item-active']: isActive(activePath, item)
                        })}>
                        <span>{item.name}</span>
                        <ul className={'sub-menu'}>
                            {item.children.map(sub => {
                                return <li key={sub.path}
                                    className={classnames('sub-menu-name', {
                                        ['sub-menu-name-active']: isActive(activePath, sub)
                                    })}
                                    onClick={() => goPage(sub)}><span>{sub.name}</span></li>
                            })}
                        </ul>
                    </li>
                }
                return <li key={item.path}
                    className={classnames('menu-item', {
                        ['menu-item-active']: isActive(activePath, item)
                    })}
                    onClick={() => goPage(item)}>{item.name}</li>
            })}
            <FormModal>
                <li className={'menu-item'}>申请体验</li>
            </FormModal>
        </ul>
    </section>
}
export default withRouter(Index);