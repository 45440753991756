export const ENV = {
  DEV: 'DEV',
  TEST: 'TEST',
  PROD: 'PROD'
};
const envMapConfig = {
  [ENV.DEV]: {
    API_URL: "/",
    PHONE_URL: 'https://test.project.hrtps.com/hrtps-com-mobile/index.html',
    SSC_PC_URL: 'https://dev.ssc.hrtps.com/chatbot-pc/#/tourist?companyId=1'
  },
  [ENV.TEST]: {
    API_URL: "https://test.project.hrtps.com",
    PHONE_URL: 'https://test.project.hrtps.com/hrtps-com-mobile/index.html',
    SSC_PC_URL: 'https://test.ssc.hrtps.com/chatbot-pc/#/tourist?companyId=1'
  },
  [ENV.PROD]: {
    API_URL: "https://project.hrtps.com",
    PHONE_URL: 'https://m.hrtps.com',
    SSC_PC_URL: 'https://ssc.hrtps.com/chatbot-pc/#/tourist?companyId=2'
  },
};

const REACT_APP_ENV = process.env.REACT_APP_ENV || ENV.DEV;

console.log('env: ', process.env.REACT_APP_ENV);

const getConfig = () => {
  return envMapConfig[REACT_APP_ENV];
};

export default getConfig();