const PHONEREG = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
// eslint-disable-next-line
export const EMAILREG = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;

export const DisableEmails = ['@gmail.com', '@yahoo.com', '@msn.com', '@hotmail.com', '@aol.com', '@ask.com',
    '@live.com', '@qq.com', '@0355.net', '@163.com', '@163.net', '@263.net', '@3721.net', '@126.com', '@yeah.net',
    '@sina.com', '@sina.com.cn', '@sohu.com', '@tom.com', '@eyou.com', '@21cn.com', '@10086.com', '@sogou.com', '@xinhuanet.com', '@91.com', '@3126.com',
    '@alibaba.com', '@188.com', '@china.com', '@cnool.net', '@aliyun.com', '@139.com'
];

export default PHONEREG;