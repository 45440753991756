import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Spin, message } from 'antd';
import Img from 'common/components/Img';
import IconForm from 'assets/images/icon_form.png';

import SuccessModal from './SuccessModal';
import PhoneReg, { EMAILREG, DisableEmails } from 'common/utils/Reg';
import { ApplyApi } from 'common/api/index';

import './style.scss';


const Index = (props) => {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [successVisible,setSuccessVisible]=useState(false)
    const [loading, setLoading] = useState(false)
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const applyAction = async (data) => {
        if (loading) {
            return
        }
        setLoading(true)

        let res = await ApplyApi({
            title: "HOME_2021",
            ...data
        })
        const success = res?.success;
        const mes = res?.data?.message;

        if (success) {
            setSuccessVisible(true)
            handleCancel()
        } else {
            message.erro(mes || "提交失败")
        }

        setTimeout(() => {
            setLoading(false)
        }, 300)
    }

    const submit = async () => {
        try {
            let data = await form.validateFields();
            applyAction(data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (isModalVisible) {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [isModalVisible])
    return <>
        <span className="open-modal-button" onClick={showModal}>
            {props.children}
        </span>
        <SuccessModal isModalVisible={successVisible} setIsModalVisible={setSuccessVisible}/>
        <Modal
            title={<Img className="icon-form" src={IconForm} />}
            wrapClassName="hrtps-form-modal"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            forceRender={true}
            width={500}>
            <div className="content">
                <div className="title">申请体验</div>
                <Form form={form} autoComplete="off">
                    <Form.Item name="userCorporation" label="企业名称" rules={[
                        { required: true, message: '请填写企业名称' },
                        {
                            validator(rule, value) {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                const val = value.trim();
                                if (!val) {
                                    return Promise.reject("请填写企业名称");
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}>
                        <Input placeholder="请填写企业名称" />
                    </Form.Item>
                    <Form.Item name="userEmail" label="公司邮箱" rules={[
                        { required: true, message: '请填写公司邮箱' },
                        {
                            validator(rule, value) {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                const val = value.trim();
                                if (!val) {
                                    return Promise.reject("请填写公司邮箱");
                                }
                                // 邮箱不合格
                                if (val && !EMAILREG.test(val)) {
                                    return Promise.reject("公司邮箱格式不正确");
                                }
                                if (val && EMAILREG.test(val)) {
                                    let last = val.split('@')
                                    last = last[last.length - 1];
                                    if (last && DisableEmails.find(i => i === `@${last}`)) {
                                        return Promise.reject("请输入公司邮箱");
                                    }
                                }
                                return Promise.resolve()
                            }
                        }
                    ]}>
                        <Input placeholder="请填写公司邮箱" />
                    </Form.Item>
                    <Form.Item name="userName" label="姓名" rules={[
                        { required: true, message: '请填写姓名' },
                        {
                            validator(rule, value) {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                const val = value.trim();
                                if (!val) {
                                    return Promise.reject("请填写姓名");
                                }
                                return Promise.resolve();
                            }
                        }]}>
                        <Input placeholder="请填写姓名" />
                    </Form.Item>
                    <Form.Item name="userPhone" label="手机号码" rules={[
                        { required: true, message: '请输入11位手机号码' },
                        {
                            validator(rule, value) {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                const val = value.trim();
                                if (!val) {
                                    return Promise.reject("请输入11位手机号码");
                                }
                                if (val && !PhoneReg.test(val)) {
                                    return Promise.reject('手机号格式不正确');
                                }
                                return Promise.resolve();
                            }
                        }]}>
                        <Input placeholder="请填写正确的手机号码" />
                    </Form.Item>
                </Form>
                <div className="button" onClick={submit}>{loading ? <Spin className="spin" size="small" /> : '即刻申请'}</div>
            </div>
        </Modal>
    </>
}
export default Index;