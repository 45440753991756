import React, { Suspense } from 'react';
import { HashRouter, BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from 'common/components/Header';
import Footer from 'common/components/Footer';
import { ENV } from 'config/index';

const REACT_APP_ENV = process.env.REACT_APP_ENV || ENV.DEV;
let Router = HashRouter;
if (REACT_APP_ENV === ENV.PROD) {
  Router = BrowserRouter;
}

function App() {
  return (
    <>
      <Router>
        <Header />
        <Suspense fallback={<div className="app-load"></div>}>
          <Switch>
            <Route exact path="/" component={React.lazy(() => import('./pages/Index'))} />
            <Route path="/product" component={React.lazy(() => import('./pages/Products'))} />
            <Route path="/about" component={React.lazy(() => import('./pages/AboutUs'))} />
            <Route path="*" component={React.lazy(() => import('./pages/Page_404'))} />
          </Switch>
        </Suspense>
        <Footer />
      </Router>
    </>

  );
}

export default App;
