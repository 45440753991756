import React from 'react';
import police_logo from 'assets/images/police_logo.png';
import './style.scss';

const Index = (props) => {
    return <section className={'footer'}>
        <span>
            客服咨询:  400-886-8523 &nbsp;&nbsp;&nbsp;&nbsp;
            周一 ～ 周五&nbsp;&nbsp; 9:00 - 18:00
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            商务合作：
            <a className={'link'} href="mailto:Sales@HRTPS.com">Sales@HRTPS.com</a>
        </span>

        <span>公司地址：上海市徐汇区龙爱路27号5楼</span>
        <span> HRTPS Copyright © 2016</span>

        <span>
            <a className={'link'} href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
                沪ICP备15045531号
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <a className={'link'}
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402004429"
                target="_blank" rel="noreferrer"
            >
                <img className={'police_logo'} src={police_logo} alt="police_logo" />
                &nbsp;沪公网安备 31010402004429号
            </a>
        </span>
    </section>
}
export default Index;
